<template>
    <div>
        <b-card>
            <h5><font-awesome-icon icon="search"></font-awesome-icon> Search Users</h5>
            <b-input
                v-model="query"
                placeholder="Start searching here"
                @keypress="fetchResults()"
            ></b-input>
            <ul
                class="list-unstyled d-inline-flex flex-wrap mb-0"
            >
                <b-card
                    v-for="user in selectedUsers"
                    :key="user.id"
                    tag="li"
                    class="mt-1 mr-1"
                    body-class="py-1 pr-2 text-nowrap"
                >
                    <template
                        v-if="user.gender != null"
                    >
                        <font-awesome-icon
                            v-if="user.gender"
                            class="mr-1"
                            icon="male"
                        ></font-awesome-icon>
                        <font-awesome-icon
                            v-else
                            class="mr-1"
                            icon="female"
                        ></font-awesome-icon>
                    </template>
                    <a
                        v-if="selectable"
                        variant="link"
                        @click="(e) => { $emit('selectUser', user, this); e.preventDefault(); }"
                        href="#"
                        class="text-left"
                    >
                        {{ user.first_name }} {{ user.last_name }}
                        <span v-if="user.nickname">({{ user.nickname }})</span>
                    </a>
                    <template v-else>
                        {{ user.first_name }} {{ user.last_name }}
                        <span v-if="user.nickname">({{ user.nickname }})</span>
                    </template>
                    <b-badge class="ml-2">
                        {{ (user.position == 0) ? "Student" : "Teacher/Parent" }}
                    </b-badge>
                    <b-spinner
                        v-if="user.working"
                        variant="primary"
                        class="ml-2"
                        small
                    ></b-spinner>
                    <b-button
                        v-else-if="canRemove"
                        @click="removeUser(user)"
                        variant="link"
                        size="sm"
                    >
                        <font-awesome-icon icon="minus-circle"></font-awesome-icon>
                        Remove
                    </b-button>
                </b-card>
            </ul>
            <b-list-group class="mt-2">
                <b-list-group-item v-if="loading" class="text-center">
                    <b-spinner variant="primary"></b-spinner>
                </b-list-group-item>
                <b-list-group-item
                    v-for="(result, idx) in results"
                    :key="idx"
                >
                    <template
                        v-if="result.gender != null"
                    >
                        <font-awesome-icon
                            v-if="result.gender"
                            class="mr-1"
                            icon="male"
                        ></font-awesome-icon>
                        <font-awesome-icon
                            v-else
                            class="mr-1"
                            icon="female"
                        ></font-awesome-icon>
                    </template>
                    {{ result.first_name }} {{ result.last_name }}
                    <span v-if="result.nickname">({{ result.nickname }})</span>
                    <b-badge class="ml-2">
                        {{ (result.position == 0) ? "Student" : "Teacher/Parent" }}
                    </b-badge>
                    <b-button
                        @click="addUser(result)"
                        class="float-right"
                        variant="link"
                        size="sm"
                    >
                        <font-awesome-icon icon="plus-circle"></font-awesome-icon>
                        Add
                    </b-button>
                </b-list-group-item>
            </b-list-group>
        </b-card>
    </div>
</template>
<script>
export default {
    props: {
        value: Array,
        selectable: {
            type: Boolean,
            default: false
        },
        canAdd: {
            type: Boolean,
            default: false
        },
        canRemove: {
            type: Boolean,
            default: false
        },
        searchUrl: {
            type: String,
            default: "/api/admin/search"
        }
    },
    data() {
        return {
            query    : "",
            results  : [],
            selectedUsers : (this.value || []),
            loading  : false
        };
    },
    methods: {
        reset() {
            this.results = [];
            this.query   = "";
        },
        addUser(user) {
            this.selectedUsers.push(user);
            this.$emit("input", this.selectedUsers);
            this.$emit("addUser", user, this);

            this.reset();
        },
        removeUser(targetUser) {
            this.selectedUsers.splice(this.selectedUsers.findIndex((user) => user.id == targetUser.id), 1);
            this.$emit("removeUser", targetUser, this);
        },
        fetchResults() {
            this.loading = true;
            this.$api.get(
                this.searchUrl,
                { params: {
                    q            : this.query,
                    target_id    : this.$route.query.target_id,
                    relationship : this.$route.query.relationship
                } }
            ).then(({ data }) => {
                this.results = data.records;
                this.loading = false;
            });
        }
    }
}
</script>