<template>
    <div class="my-4">
        <h5>My Permissions</h5>
        <div class="my-2">
            <button
                @click="toggleMyPermissions();"
                class="sc-btn pill"
            >
                <font-awesome-icon class="mr-2" :icon="(showMyPermissions) ? 'eye-slash' : 'eye'"></font-awesome-icon>
                {{ (showMyPermissions) ? "Hide" : "Show" }}
            </button>
        </div>
        <b-skeleton-wrapper
            v-if="showMyPermissions"
            :loading="loading"
        >
            <template #loading>
                <div class="text-center my-2">
                    <b-spinner variant="primary"></b-spinner>
                </div>
            </template>
            <b-card
                title="You have been granted access to this resource to perform the following actions:"
            >
                <ul>
                    <li
                        v-for="(permission, idx) in permissions"
                        :key="idx"
                        variant="primary"
                    >
                        <b>{{ permission }}</b>
                        <p>{{ supportedPermissions[permission] || "No description." }}</p>
                    </li>
                </ul>
            </b-card>
        </b-skeleton-wrapper>
    </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
    props: {
        resourceKey: String
    },
    data() {
        return {
            showMyPermissions: false,
            permissions: [],
            supportedPermissions: {},
            loading: true
        };
    },
    methods: {
        toggleMyPermissions() {
            this.showMyPermissions = !this.showMyPermissions;
            if (this.showMyPermissions)
                this.getUserResourcePermissions(this.user.id);
        },
        getSupportedPermissions() {
            this.loading = true;
            return this.$api.get(
                `/api/authorization/resource/${this.resourceKey}/get-supported-permissions`
            );
        },
        getUserResourcePermissions(user_id) {
            this.loading = true;
            this.getSupportedPermissions().then(({ data: { supportedPermissions } }) => {
                this.supportedPermissions = supportedPermissions;
                this.$api.get(
                    `/api/authorization/resource/${this.resourceKey}/get-user-permissions`,
                    {
                        params: { user_id: user_id }
                    }
                ).then(({ data: { success, permissions } }) => {
                    if (success)
                        this.permissions = permissions.split(",");
                    this.loading = false;
                });
            });
        }
    },
    computed: mapState(["user"])
}
</script>
<style scoped>
    .permission-group {
        display: flex;
        gap: 0.25rem;
    }
    .permission-group > span {
        min-width: 10rem;
        padding: 1rem 0;
    }
</style>