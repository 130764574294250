<template>
    <b-skeleton-wrapper
        :loading="loading"
    >
        <template #loading>
            <table
                class="table table-bordered mt-2"
            >
                <tr class="text-center">
                    <td><b-spinner variant="primary"></b-spinner></td>
                </tr>
            </table>
        </template>
        <div class="my-4">
            <h5>Resource Info</h5>
            <table
                class="table table-bordered mt-2"
            >
                <tr
                    v-for="(infoValue, infoKey) in info"
                    :key="infoKey"
                >
                    <td>{{ infoKey }}</td><td>{{ infoValue }}</td>
                </tr>
            </table>
        </div>
    </b-skeleton-wrapper>
</template>
<script>
export default {
    props: {
        resourceKey: String
    },
    data() {
        return {
            loading: true,
            info:    {},
            exists:  true
        };
    },
    mounted() {
        this.loadResourceInfo();
    },
    methods: {
        loadResourceInfo() {
            this.loading = true;
            this.$api.get(
                `/api/authorization/resource/${this.resourceKey}/info`
            ).then(({ data }) => {
                this.info    = data;
                this.loading = false;
            });
        }
    }
}
</script>
<style scoped>
    table tr td:first-of-type {
        font-weight: bold;
        text-transform: capitalize;
    }
</style>