<template>
    <b-modal
        v-model="showPermissions"
        @show="resetModal"
        @hidden="resetModal"
        @ok="setPermissions"
        size="lg"
    >
        <template #modal-title>
            Grant {{ user.first_name }} {{ user.last_name }} access to
        </template>
        <b-skeleton-wrapper
            :loading="loading"
        >
            <template #loading>
                <div class="text-center">
                    <b-spinner variant="primary"></b-spinner>
                </div>
            </template>
            <div>
                <div class="text-center my-2">
                    <b-checkbox
                        size="lg"
                        switch
                        @change="changeAll"
                        :checked="allSelected"
                    >
                        Select All
                    </b-checkbox>
                </div>
                <div class="switch-container">
                    <b-check-group
                        v-model="selected_permissions"
                        :options="permissions"
                        size="lg"
                        switches
                        @change="onAccessChange"
                    ></b-check-group>
                </div>
            </div>
        </b-skeleton-wrapper>
    </b-modal>
</template>
<script>
export default {
    props: {
        id: String,
        user:  Object,
        value: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showPermissions: this.value,
            selected_permissions: [],
            granted_permissions: [],
            loading: true,
            permissions: []
        }
    },
    mounted() {
        this.getSupportedPermissions();
    },
    methods: {
        onAccessChange() {
            console.log(arguments);
        },
        getUserResourcePermissions(user_id) {
            this.loading = true;
            this.$api.get(
                `/api/authorization/resource/${this.id}/get-user-permissions`,
                {
                    params: { user_id: user_id }
                }
            ).then(({ data: { success, permissions } }) => {
                if (success && typeof permissions == "string") {
                    this.selected_permissions = permissions.split(","); // Used for checkbox v-model
                    this.granted_permissions  = permissions.split(",");
                }
                this.loading = false;
            });
        },
        getSupportedPermissions() {
            this.loading = true;
            this.$api.get(
                `/api/authorization/resource/${this.id}/get-supported-permissions`
            ).then(({ data: { supportedPermissions } }) => {
                this.permissions = Object.entries(supportedPermissions).map(([permission_name, permission_info]) => {
                    return { text: permission_info, value: permission_name };
                });
                this.getUserResourcePermissions(this.user.id);
            });
        },
        setPermissions() {
            this.selected_permissions.join(",");
            alert("SET PERMISSIONS");
            let requested_permissions = this.selected_permissions.filter((permission) => !this.granted_permissions.includes(permission));
            this.$api.post(
                "/api/authorization/resource/grant-user-permissions",
                {
                    resource_key: this.id,
                    user_id:      this.user.id,
                    permissions:  requested_permissions
                }
            ).then(({ data }) => {
                console.log(data);
            });

            let revoked_permissions = this.granted_permissions.filter((permission) => !this.selected_permissions.includes(permission));
            this.$api.post(
                "/api/authorization/resource/revoke-user-permissions",
                {
                    resource_key: this.id,
                    user_id:      this.user.id,
                    permissions:  revoked_permissions
                }
            ).then(({ data }) => {
                console.log(data);
            });
            // User must have "ASSIGN" permission. Then, the user may only assign permissions they have.
        },
        resetModal() {
            this.showPermissions = false;
            this.getUserResourcePermissions(this.user.id);
            this.$emit("input", false);
        },
        changeAll(selectAll) {
            if (selectAll)
                this.permissions.forEach(({ disabled, value }) => {
                    if (!this.selected_permissions.includes(value) && !disabled) this.selected_permissions.push(value);
                });
            else
                this.selected_permissions.splice(0, this.selected_permissions.length);
        }
    },
    watch: {
        value(newValue) {
            this.showPermissions = newValue;
        }
    },
    computed: {
        allSelected() {
            return this.selected_permissions.length == this.permissions.length;
        }
    }
}
</script>
<style scoped>
    .switch-container > div {
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 0.5rem;
    }
</style>