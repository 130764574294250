<template>
    <div>
        <b-list-group class="mb-4">
            <b-list-group-item variant="secondary">
                <h4>
                    <font-awesome-icon icon="key" class="mr-2"></font-awesome-icon>
                    Manage Resource Access <b-badge variant="warning">Beta</b-badge>
                </h4>
            </b-list-group-item>
        </b-list-group>
        <!--<resource-info></resource-info>-->
        <b-alert
            :show="true"
            variant="warning"
        >
            <font-awesome-icon icon="exclamation-triangle" class="mr-2"></font-awesome-icon>
            Not all resource permissions are operational. Resource access is still in development.
        </b-alert>
        <ResourceInfo
            :resourceKey="resourceKey"
        ></ResourceInfo>
        <MyResourcePermissions
            :resourceKey="resourceKey"
        ></MyResourcePermissions>
        <GrantResourcePermissions
            :resourceKey="resourceKey"
        ></GrantResourcePermissions>
    </div>
</template>
<script>
import ResourceInfo from './ResourceInfo.vue';
import MyResourcePermissions from './MyResourcePermissions.vue';
import GrantResourcePermissions from './GrantResourcePermissions.vue';

export default {
    props: {
        resourceKey: String
    },
    components: { ResourceInfo, MyResourcePermissions, GrantResourcePermissions }
}
</script>