<template>
    <div class="my-4">
        <h5>Grant Permissions:</h5>
        <search-people
            class="mt-2"
            :key="grantedAccess.length"
            v-model="grantedAccess"
            :selectable="true"
            @selectUser="selectUser"
            @addUser="addUser"
            @removeUser="removeUser"
            :canRemove="permissions.includes('REVOKE')"
            :canAdd="permissions.includes('ASSIGN')"
            searchUrl="/api/admin/users/search"
        ></search-people>
        <select-access
            :key="'user-' + selectedUser.id"
            :id="resourceKey"
            :user="selectedUser"
            v-model="grantUserAccess"
        ></select-access>
    </div>
</template>
<script>
import SearchPeople from '@/components/SearchPeople.vue';
import SelectAccess from './SelectAccess.vue';
import { mapState } from 'vuex';

export default {
    props: {
        resourceKey: String
    },
    data() {
        return {
            info: {},
            grantedAccess: [],
            grantUserAccess: false,
            selectedUser: {},
            permissions: []
        };
    },
    mounted() {
        this.listResourceGrants();
        this.getUserPermissions();
    },
    methods: {
        getUserPermissions() {
            this.loading = true;
            this.$api.get(
                `/api/authorization/resource/${this.resourceKey}/get-user-permissions`,
                { params: { user_id: this.user.id } }
            ).then(({ data: { success, permissions } }) => {
                if (success && typeof permissions == "string")
                    this.permissions = permissions.split(",");
                this.loading = false;
            });
        },
        listResourceGrants() {
            this.$api.get(
                `/api/authorization/resource/${this.resourceKey}/list`
            ).then(({ data: { success, records } }) => {
                if (success)
                    this.grantedAccess = records;
            });
        },
        addUser(user) {
            this.$api.post(
                `/api/authorization/resource/grant-user-permissions`,
                {
                    resource_key: this.resourceKey,
                    user_id:      user.id,
                    permissions:  [ "VIEW" ]
                }
            ).then(({ data: { success } }) => {
                user.status = (success)
                user.working = false;
                this.selectUser(user);
            });
        },
        selectUser(user) {
            Object.assign(this.selectedUser, user);
            user.working = true;
            this.grantUserAccess = true;
        },
        removeUser(targetUser, context) {
            targetUser.working = true;
            this.$api.post(
                `/api/authorization/resource/revoke-user-permissions`,
                {
                    resource_key: this.resourceKey,
                    user_id:      targetUser.id,
                    permissions:  [ "*" ]
                }
            ).then(({ data: { success } }) => {
                targetUser.status = success;
                targetUser.working = false;
                if (!success)
                    context.addUser(targetUser);
            });
        }
    },
    components: { SearchPeople, SelectAccess },
    computed: mapState(["user"])
}
</script>